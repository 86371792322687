<div class="sidebar">
  <a class="active" [routerLink]="['/admin/agent-dashboard']">Dashboard</a>
  <a [routerLink]="['/admin/settings']" *ngIf="addAccess">Email Templates</a>
</div>

<div class="content">
  <h4 class="text-uppercase header">DASHBOARD | {{ userTypeName }}</h4>
  <div class="rel-action" (click)="openAddLicensePopUp()">
    <button class="btn add-btn" title="Add Organization">
      <img src="assets/images/plus-icon.svg" height="35px;" />
    </button>
  </div>

  <div class="organization-table">
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Organization</th>
          <th scope="col">UC User</th>
          <th scope="col">CC User</th>
          <th scope="col">Created At</th>
          <th *ngIf="addAccess" scope="col">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of parentUserList
              | paginate
                : {
                    itemsPerPage: userPaginator.limit,
                    currentPage: userPaginator.skip,
                    totalItems: userPaginator.total
                  }
          "
        >
          <th scope="row" class="changeTexTransform">
            {{ data.organization.name }}
          </th>
          <td>{{ data.uc_user }}</td>
          <td>{{ data.cc_user }}</td>
          <td>{{ (data.creationDate | date) || "-" }}</td>
          <td class="text-left pull-left" *ngIf="addAccess">
            <button
              class="btn btn-sm btn-warning mar-5-left"
              title="Edit License"
              (click)="editUserLicense(data)"
            >
              <img src="assets/images/edit-icon.svg" />
            </button>
            <button
              class="btn btn-sm btn-danger mar-5-left"
              title="Delete License"
              (click)="deleteUserLicense(data)"
            >
              <img src="assets/images/trash.svg" />
            </button>
          </td>
        </tr>
        <tr class="total_tr">
          <td style="text-align: right">Total :</td>
          <td>{{ totalUCUser }}</td>
          <td>{{ totalCCUser }}</td>
          <td></td>
          <td *ngIf="addAccess"></td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="getUserList($event)"
    ></pagination-controls>
  </div>
  <br />
</div>

<!-- <div class="container-fluid">
  
</div> -->

<!--Add Modal -->
<div
  class="modal fade"
  id="addLicenseModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addLicenseModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">ADD LICENSE | NEW</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form [formGroup]="userForm" (ngSubmit)="addLicense()">
          <div>
            <label class="required">Organization</label>
            <select
              class="dropdown"
              id="parent"
              formControlName="orgId"
              required
            >
              <option value="" disabled selected>Select Organization</option>
              <option *ngFor="let item of parentOrgList" value="{{ item._id }}">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div style="margin-top: 5px">
            <label class="required">UC User</label>
            <input
              class="input-field"
              placeholder="Enter UC User"
              formControlName="uc_user"
              required
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">CC User</label>
            <input
              class="input-field"
              placeholder="Enter CC User"
              formControlName="cc_user"
              required
            />
          </div>
          <hr />
          <button
            type="submit"
            class="btn btn-sm btn-primary"
            [disabled]="!userForm.valid"
          >
            Add License
          </button>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!--Edit Modal -->
<div
  class="modal fade"
  id="editLicenseModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editLicenseModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">LICENSE | EDIT</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form [formGroup]="userForm" (ngSubmit)="updateUserLicense()">
          <div>
            <label class="required">Organization</label>
            <input
              class="input-field"
              disabled
              id="disable_org"
              placeholder="Enter Organization"
              formControlName="orgId"
              required
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">UC User</label>
            <input
              class="input-field"
              placeholder="Enter UC User"
              formControlName="uc_user"
              required
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">CC User</label>
            <input
              class="input-field"
              placeholder="Enter CC User"
              formControlName="cc_user"
              required
            />
          </div>
          <hr />
          <button
            type="submit"
            class="btn-sm btn btn-primary"
            [disabled]="!userForm.valid"
          >
            Edit License
          </button>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">LICENSE | DELETE</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <label>Do you want to delete this user License - {{ orgName }}?</label>
        <br />
        <button
          type="button"
          class="btn-sm btn delete-btn btn-light"
          data-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          class="btn-sm btn delete-btn btn-primary"
          (click)="deleteLicense()"
        >
          Yes
        </button>
        <br /><br />
      </div>
    </div>
  </div>
</div>
