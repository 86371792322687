import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Organization } from '../../models/organization.model';
import { AuthService } from '../../auth/auth.service';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { OrganizationIdSharingService } from '../../service/data.service';
import { Roles } from '../../models/role.model';
import { Subscription } from 'rxjs';
declare let $: any;

@Component({
  selector: 'app-agent-dashboard',
  templateUrl: './agent-dashboard.component.html',
  styleUrls: ['./agent-dashboard.component.scss'],
})
export class AgentDashboardComponent implements OnInit {
  @Output() sendOrgList = new EventEmitter<Organization[]>();

  orgListSubscriptiion: Subscription;
  addUserSubscription: Subscription;
  editUserSubscription: Subscription;
  deleteUserSubscription: Subscription;
  userForm: FormGroup;
  parentUserList: Organization[];
  parentOrgList: Organization[];
  orgList: Organization[];
  orgChildList: Organization[];
  orgName: any;
  userId: any;
  orgId: any;
  totalCCUser: any;
  totalUCUser: any;
  userName: any;
  permissions: [] = [];
  userType: any;
  userTypeName: any;
  userPaginator = { limit: 10, skip: 1, total: 0 };
  addAccess: Boolean;

  constructor(
    private authService: AuthService,
    private toastr: ToastrService,
    private fb: FormBuilder,
    private sharingService: OrganizationIdSharingService
  ) {}

  ngOnInit(): void {
    this.formInit();
    this.getUserList(1);
    this.getOrganizations();

    this.userType = localStorage.getItem('userRole');
    if (this.userType == 'admin' || this.userType == 'Admin') {
      this.userTypeName = 'ADMIN';
      this.addAccess = false;
    } else {
      this.userTypeName = 'SUPER ADMIN';
      this.addAccess = true;
    }
  }

  ngOnDestroy(): void {
    $('#org-dropdown-navbar').prop('disabled', false);
    this.orgListSubscriptiion.unsubscribe();
    this.addUserSubscription?.unsubscribe();
    this.editUserSubscription?.unsubscribe();
    this.deleteUserSubscription?.unsubscribe();
  }

  formInit() {
    this.userForm = this.fb.group({
      cc_user: ['', Validators.required],
      uc_user: [''],
      orgId: ['', Validators.required],
    });
  }

  getOrganizations() {
    let dataToSend = {
      skip: 1,
      limit: 10,
    };
    this.orgListSubscriptiion = this.authService
      .getOrganizationList(dataToSend)
      .subscribe(
        (res) => {
          if (res['success']) {
            this.parentOrgList = res['data'];
            console.log('all list of org', res['data']);
          } else {
            this.toastr.error(res['message'], 'Error!');
          }
        },
        () => {
          this.toastr.error('Something went wrong', 'Error!');
        }
      );
  }

  getUserList(skip) {
    this.userPaginator.skip = skip;
    let dataToSend = {
      skip: this.userPaginator.skip,
      limit: this.userPaginator.limit,
    };
    this.orgListSubscriptiion = this.authService
      .getUserLicenseList(dataToSend)
      .subscribe(
        (res) => {
          if (res['success']) {
            this.parentUserList = res['data'];
            this.totalCCUser = res['total_cc_user'];
            this.totalUCUser = res['total_uc_user'];

            this.userPaginator.total = res['data'].length;
          } else {
            this.toastr.error(res['message'], 'Error!');
          }
        },
        () => {
          this.toastr.error('Something went wrong', 'Error!');
        }
      );
  }

  openAddLicensePopUp() {
    this.userForm.reset();
    $('#addLicenseModal').modal('show');
  }

  addLicense() {
    this.addUserSubscription = this.authService
      .addUserLicense(this.userForm.value)
      .subscribe(
        (res) => {
          if (res['success']) {
            this.toastr.success(res['message'], 'Success!');
            $('#addLicenseModal').modal('hide');
            this.userForm.reset();
            // this.getOrganizations();
            this.getUserList(1);
          } else {
            this.toastr.error(res['message'], 'Error!');
          }
        },
        () => {
          this.toastr.error('Something went wrong', 'Error!');
        }
      );
  }

  deleteUserLicense(orgData: Organization) {
    console.log(orgData);

    if (orgData) {
      this.orgId = orgData['_id'];
      this.orgName = orgData['organization']['name'];
      $('#deleteModal').modal('show');
    }
  }

  deleteLicense() {
    this.deleteUserSubscription = this.authService
      .deleteUserLicense(this.orgId)
      .subscribe(
        (res) => {
          if (res['success']) {
            this.toastr.success('License Deleted', 'Success!');
            $('#deleteModal').modal('hide');
            this.userForm.reset();
            this.getUserList(1);
          } else {
            this.toastr.error(res['message'], 'Error!');
          }
        },
        () => {
          this.toastr.error('Something went wrong', 'Error!');
        }
      );
  }

  editUserLicense(orgData: Organization) {
    console.log(orgData);
    if (orgData) {
      this.orgId = orgData['_id'];
      this.userForm.patchValue({
        orgId: orgData['organization']['name'],
        uc_user: orgData['uc_user'],
        cc_user: orgData['cc_user'],
      });
      $('#editLicenseModal').modal('show');
    }
  }

  updateUserLicense() {
    let data = this.userForm.value;
    data['id'] = this.orgId;

    this.editUserSubscription = this.authService
      .updateUserLicense(data, this.orgId)
      .subscribe(
        (res) => {
          if (res['success']) {
            this.toastr.success('License Edited', 'Success!');
            $('#editLicenseModal').modal('hide');
            this.userForm.reset();
            this.getUserList(1);
          } else {
            this.toastr.error(res['message'], 'Error!');
          }
        },
        () => {
          this.toastr.error('Something went wrong', 'Error!');
        }
      );
  }
}
