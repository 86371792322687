<div class="container-fluid">
  <h4 class="text-uppercase header">PACKAGE | MASTER</h4>
  <div class="rel-action" (click)="openMasterPopUp()">
    <button class="btn add-btn" title="Add Package">
      <img src="assets/images/plus-icon.svg" height="35px;" />
    </button>
  </div>
  <div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Package</th>
          <th>Status</th>
          <th scope="col">Created At</th>
          <th scope="col">Updated At</th>
          <th scope="col" class="text-right pull-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of packgList
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: 1,
                    totalItems: packPaginator.total
                  }
          "
        >
          <th scope="row">
            {{ data.currencySymbol }} {{ data.packageAmount }}
          </th>
          <td>Active</td>
          <td>{{ data.creationDate | date }}</td>
          <td>{{ (data.updationDate | date) || "-" }}</td>
          <td class="text-right pull-right">
            <button
              class="btn btn-sm btn-warning mar-5-left"
              title="Edit Package"
              (click)="editPack(data)"
            >
              <img src="assets/images/edit-icon.svg" />
            </button>
            <button
              class="btn btn-sm btn-danger mar-5-left"
              title="Delete Package"
              (click)="delete(data)"
            >
              <img src="assets/images/trash.svg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="getPackList($event)"
    ></pagination-controls>
  </div>
  <br />
</div>

<!--Add Modal -->
<div
  class="modal fade"
  id="addPackModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addPackModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">PACKAGE | ADD</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form
          [formGroup]="paymentMasterForm"
          (ngSubmit)="addPackageMaster(paymentMasterForm.value)"
        >
          <div style="margin-top: 5px">
            <label class="required">Currency</label>
            <select class="dropdown" formControlName="currency">
              <option value="" disabled selected>Select Currency</option>
              <option *ngFor="let data of currencyList" [value]="data._id">
                {{ data.symbol }} - {{ data.name }}
              </option>
            </select>
          </div>
          <div style="margin-top: 5px">
            <label class="required">Package</label>
            <input
              type="text"
              id="packageAmount"
              class="input-field required"
              placeholder="Enter package"
              (keypress)="numericOnly($event)"
              formControlName="packageVal"
            />
            <!-- <span class="notice"
              >Note: The amount you entered, is automatically converted as
              dollar ($)</span
            > -->
            <hr />
            <button
              class="btn btn-sm btn-primary"
              type="submit"
              [disabled]="!paymentMasterForm.valid"
            >
              Add Package
            </button>
          </div>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!--Edit Modal -->
<div
  class="modal fade"
  id="editPackModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editPackModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">PACKAGE | EDIT</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form
          [formGroup]="paymentMasterForm"
          (ngSubmit)="updatePack(paymentMasterForm.value)"
        >
          <div style="margin-top: 5px">
            <label class="required">Currency</label>
            <!-- <select class="dropdown" formControlName="currency">
              <option [value]="null" disabled selected>Select Currency</option>
              <option *ngFor="let data of currencyList" [value]="data._id">
                {{ data.symbol }} - {{ data.name }}
              </option>
            </select> -->
            <input
              class="input-field"
              placeholder=""
              disabled
              id="currencySymbol"
              formControlName="currency"
              required
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">Enter Package</label>
            <input
              class="input-field"
              placeholder="Enter Package"
              formControlName="packageVal"
              (keypress)="numericOnly($event)"
              required
            />
          </div>
          <hr />
          <button type="submit" class="btn btn-sm btn-primary">
            Edit Package
          </button>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">PACKAGE | DELETE</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <label>Do you want to delete this package- ${{ packageName }}?</label>
        <br />
        <button
          type="button"
          class="btn-sm btn delete-btn btn-light"
          data-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          class="btn-sm btn delete-btn btn-primary"
          (click)="deletePackage()"
        >
          Yes
        </button>
        <br /><br />
      </div>
    </div>
  </div>
</div>
