import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { AuthService } from './auth.service';

@Injectable({providedIn: 'root'})

export class AuthGuard implements CanActivate {
  constructor (private authService:AuthService, private router:Router){

  }

  canActivate() {
    if(this.authService.loggedIn() && !!this.authService.loadSessionToken()) {
      return true;
    } else {
      this.authService.logout().subscribe((res) => {
        if (res['success']) {
          localStorage.clear();
              } 
        });
      this.router.navigate(['/login']);
      return false;
    }
  }
}
