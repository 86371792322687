<div class="container-fluid">
  <h4 class="text-uppercase header">PACKAGE ASSIGNED ORGANIZATIONS</h4>
  <div class="addPackage">
    <button
      [routerLink]="['/admin/payment-master']"
      class="btn add-btn"
      title="Add Package"
    >
      Package Master
    </button>
  </div>
  <div class="rel-action" (click)="openPaymentPopUp()">
    <button class="btn add-btn" title="Add Payment">
      <img src="assets/images/plus-icon.svg" height="35px;" />
    </button>
  </div>
  <div>
    <table class="table table-bordered">
      <thead>
        <tr>
          <th scope="col">Assinged Credit</th>
          <th scope="col" style="width: 20%">Organization</th>
          <th scope="col">Available Credit</th>
          <th scope="col">Added Date</th>
          <th scope="col">Updated Date</th>
          <th scope="col">Activity</th>
          <th scope="col" class="text-right pull-right">Actions</th>
        </tr>
      </thead>
      <tbody>
        <tr
          *ngFor="
            let data of paymentList
              | paginate
                : {
                    itemsPerPage: 10,
                    currentPage: 1,
                    totalItems: payPaginator.total
                  }
          "
        >
          <th scope="row">{{ data.currencySymbol }} {{ data.package }}</th>
          <td>{{ data.orgName || "-" }}</td>
          <td class="tdPaymentExp">
            <span *ngIf="data.paymentGoingToExpire" class="showAvaiAmt"
              >{{ data.currencySymbol }} {{ data.availablePackage }}</span
            >
            <span *ngIf="!data.paymentGoingToExpire"
              >{{ data.currencySymbol }} {{ data.availablePackage }}</span
            >
            <div *ngIf="data.paymentGoingToExpire">
              <span id="showLimitExpire">Limit going to Expire</span
              ><span id="sendNotiBtn" (click)="notifyAlertMail(data)"
                >Send notification to mail</span
              >
            </div>
          </td>
          <td>{{ (data.creationDate | date: "medium") || "-" }}</td>
          <td>{{ (data.updationDate | date: "medium") || "-" }}</td>
          <td>Active</td>
          <td class="text-right pull-right">
            <button
              class="btn btn-sm btn-warning mar-5-left"
              title="Edit Payment"
              value="{{ data._id }}"
              (click)="editPayment(data)"
            >
              <img src="assets/images/edit-icon.svg" />
            </button>

            <button
              class="btn btn-sm btn-danger mar-5-left"
              title="Delete Payment"
              (click)="deletePaymentIcon(data)"
              value="{{ data._id }}"
            >
              <img src="assets/images/trash.svg" />
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <pagination-controls
      (pageChange)="getPaymentOrgList($event)"
    ></pagination-controls>
  </div>
  <br />
</div>

<!--Add Modal -->
<div
  class="modal fade"
  id="addOrgModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="addOrgModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">Assign the Package</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form [formGroup]="paymentForm">
          <div>
            <label class="required">Organization</label>
            <select
              class="dropdown"
              id="parent"
              formControlName="parent"
              required
              name="chooseOrg"
              (change)="onChangeGetVal($event)"
            >
              <option [ngValue]="null" disabled>Select Organization</option>
              <option *ngFor="let item of parentOrgList" value="{{ item._id }}">
                {{ item.name }}
              </option>
            </select>
          </div>
          <div style="margin-top: 5px">
            <label class="required">Package</label>
            <select
              class="dropdown"
              id="package"
              formControlName="package"
              required
            >
              <option [ngValue]="null" disabled>Select Package</option>
              <option
                *ngFor="let packages of packageDetails"
                [value]="packages._id"
                [selected]="packages.value == 'null'"
              >
                {{ packages.packageName }}
              </option>
            </select>
          </div>
          <div style="margin-top: 5px">
            <label class="required">OTP</label
            ><span *ngIf="divCodeSentTo" class="code-sent-to"
              >( code is sent to {{ paymentEmail }} )</span
            >

            <input
              type="text"
              class="input-field verify-icon"
              placeholder="Enter OTP"
              formControlName="otp"
              (keypress)="numericOnly($event)"
              id="otp_val"
              maxlength="6"
              required
            />
            <!-- <span class="otp-error">The code you entered was incorrect</span> -->
            <button
              type="submit"
              *ngIf="butSendOtp"
              class="send-otp-verify"
              (click)="sendOtp()"
            >
              Send OTP
            </button>
            <button
              *ngIf="butVerifyOtp"
              type="button"
              class="send-otp-verify-otp"
              (click)="verifyOtp('add')"
            >
              Verify OTP
            </button>
            <span class="resend-optSpan">Didn't Receive code?</span
            ><button
              type="button"
              disabled
              class="resend-otp"
              (click)="resendotp()"
            >
              Request again</button
            ><span *ngIf="divTimer" class="timer-otp">( {{ timeLeft }} )</span>
          </div>
          <hr />
          <button
            type="button"
            id="addPackBtn"
            class="btn btn-sm btn-primary"
            [disabled]="!paymentForm.valid"
            (click)="addPackage()"
          >
            Add Package
          </button>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!--Edit Modal -->
<div
  class="modal fade"
  id="editPaymentModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="editPaymentModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">PAYMENT | EDIT</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <form [formGroup]="paymentEditForm" (ngSubmit)="updatePayment()">
          <div style="margin-top: 5px">
            <label class="required">Update Package</label>
            <select
              class="dropdown"
              id="packageEdit"
              formControlName="packageEditName"
              required
              #mySelect
              (change)="onOptionsSelected($event)"
            >
              <option value="" selected disabled>Select Package</option>
              <option
                *ngFor="let packages of optionSelectedVal"
                [value]="packages.packageAmount"
              >
                {{ packages.packageName }}
              </option>
              <!-- <option [value]="1000">Package Two ( $1000 )</option> -->
            </select>
          </div>
          <div style="margin-top: 5px">
            <label class="required">Assigned Credit</label>
            <input
              class="input-field"
              placeholder="Enter Credit"
              formControlName="assignedAmount"
              id="avilAmt"
              required
              disabled
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">Available Credit</label>
            <input
              class="input-field"
              placeholder="Enter Pending Credit"
              formControlName="pendingAmount"
              id="pendAmt"
              required
              disabled
            />
          </div>
          <div style="margin-top: 5px">
            <label class="required">Organization</label>
            <input
              class="input-field"
              id="editOrgDisabled"
              placeholder="Enter Organization"
              formControlName="organizationEdit"
              required
              disabled
            />
          </div>

          <div style="margin-top: 5px">
            <label class="required">OTP</label
            ><span *ngIf="divCodeSentTo" class="code-sent-to"
              >( code is sent to {{ paymentEmail }} )</span
            >

            <input
              type="text"
              class="input-field verify-icon"
              placeholder="Enter OTP"
              (keypress)="numericOnly($event)"
              formControlName="otp_edit"
              id="otp_valEdit"
              maxlength="6"
              required
            />
            <!-- <span class="otp-error">The code you entered was incorrect</span> -->
            <button
              type="button"
              *ngIf="butSendOtp"
              class="send-otp-verify"
              (click)="sendOtpEdit()"
            >
              Send OTP
            </button>
            <button
              *ngIf="butVerifyOtp"
              type="button"
              class="send-otp-verify-otp"
              (click)="verifyOtp('edit')"
            >
              Verify OTP
            </button>
            <span class="resend-optSpan">Didn't Receive code?</span
            ><button
              type="button"
              disabled
              class="resend-otp"
              (click)="resendotp()"
            >
              Request again</button
            ><span *ngIf="divTimer" class="timer-otp">( {{ timeLeft }} )</span>
          </div>

          <hr />
          <button
            type="submit"
            class="btn-sm btn btn-primary"
            [disabled]="!paymentEditForm.valid"
          >
            Edit Payment
          </button>
        </form>
        <br />
      </div>
    </div>
  </div>
</div>

<!-- Delete Modal -->
<div
  class="modal fade"
  id="deleteModal"
  tabindex="-1"
  role="dialog"
  aria-labelledby="deleteModalLabel"
  aria-hidden="true"
>
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="container">
        <h6 class="heading">PAYMENT | DELETE</h6>
        <button
          type="button"
          class="close"
          data-dismiss="modal"
          aria-label="Close"
        >
          <span aria-hidden="true">&times;</span>
        </button>
        <hr />
        <label
          >Do you want to delete this payment related to organization-
          {{ paymentName }}?</label
        >
        <br />
        <button
          type="button"
          class="btn-sm btn delete-btn btn-light"
          data-dismiss="modal"
        >
          No
        </button>
        <button
          type="button"
          class="btn-sm btn delete-btn btn-primary"
          (click)="deletePayment()"
        >
          Yes
        </button>
        <br /><br />
      </div>
    </div>
  </div>
</div>
