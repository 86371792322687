<div class="sidebar">
  <!-- <a [routerLink]="['/admin/agent-dashboard']">Dashboard</a> -->
  <a class="active" [routerLink]="['/admin/settings']" *ngIf="addAccess"
    >Email Templates</a
  >
</div>

<div class="content">
  <h2 class="text-uppercase header">Manage Email Templates</h2>
  <section>
    <div class="card" style="width: 100%">
      <div class="card-body">
        <ul
          class="nav nav-pills mb-3 border-bottom pb-3"
          id="pills-tab"
          role="tablist"
        >
          <li class="nav-item" role="presentation">
            <a
              class="nav-link active"
              id="pills-home-tab"
              data-toggle="pill"
              href="#pills-home"
              role="tab"
              aria-controls="pills-home"
              aria-selected="true"
              >Template 1</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-profile-tab"
              data-toggle="pill"
              href="#pills-profile"
              role="tab"
              aria-controls="pills-profile"
              aria-selected="false"
              >Template 2</a
            >
          </li>
          <li class="nav-item" role="presentation">
            <a
              class="nav-link"
              id="pills-contact-tab"
              data-toggle="pill"
              href="#pills-contact"
              role="tab"
              aria-controls="pills-contact"
              aria-selected="false"
              >Template 3</a
            >
          </li>
        </ul>
        <div class="tab-content" id="pills-tabContent">
          <div
            class="tab-pane fade show active"
            id="pills-home"
            role="tabpanel"
            aria-labelledby="pills-home-tab"
          >
            <h1 class="mb-3">Template for sending OTP</h1>
            <div class="form col-6">
              <form
                [formGroup]="otpTemplate"
                (ngSubmit)="setOTPTemp(otpTemplate.value)"
              >
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput2"
                    >To Email Address</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="to"
                    id="to_email"
                    placeholder="Enter To email address"
                    value="{{ toAddress }}"
                  />
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput2"
                    >Subject</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="subject"
                    id="subject"
                    placeholder="Enter Subject.. (eg) Call Billing - Payment Verification OTP"
                    value="{{ subject }}"
                  />
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput1"
                    >Name</label
                  >
                  <input
                    type="text"
                    formControlName="name"
                    class="form-control"
                    placeholder="Enter Name.. (eg) Dear Admin,"
                    id="title"
                    value="{{ title }}"
                  />
                </div>

                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Body</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    formControlName="body"
                    placeholder="Enter some message"
                    id="body"
                    value="{{ body }}"
                  ></textarea>
                </div>

                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Signature</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Enter Signature"
                    value="{{ signature }}"
                    formControlName="signature"
                    id="sign"
                  ></textarea>
                </div>
                <div>
                  <!-- <button
                    class="btn btn-outline-primary mr-2"
                    type="button"
                    (click)="(clearFormOtp)"
                  > 
                    Reset
                  </button>-->
                  <button class="btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-profile"
            role="tabpanel"
            aria-labelledby="pills-profile-tab"
          >
            <h1 class="mb-3">Template for Credits Going to Expire</h1>
            <div class="form col-6">
              <form
                [formGroup]="expireTemplate"
                (ngSubmit)="setExpireTemp(expireTemplate.value)"
              >
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput2"
                    >Subject</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="subject1"
                    id="subject1"
                    placeholder="Enter Subject.. (eg) Call Billing - Notify for Payment"
                    value="{{ subject1 }}"
                  />
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput1"
                    >Name</label
                  >
                  <input
                    type="text"
                    formControlName="name1"
                    class="form-control"
                    placeholder="Enter Name.. (eg) Dear Admin,"
                    id="title1"
                    value="{{ title1 }}"
                  />
                </div>

                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Body</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    formControlName="body1"
                    placeholder="Enter some message"
                    id="body1"
                    value="{{ body1 }}"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Signature</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Enter Signature"
                    value="{{ signature1 }}"
                    formControlName="signature1"
                    id="sign1"
                  ></textarea>
                </div>
                <div>
                  <!-- <button class="btn btn-outline-primary mr-2" type="submit">
                    Reset
                  </button> -->
                  <button class="btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </div>
          </div>
          <div
            class="tab-pane fade"
            id="pills-contact"
            role="tabpanel"
            aria-labelledby="pills-contact-tab"
          >
            <h1 class="mb-3">Template for Credits Expired</h1>
            <div class="form col-6">
              <form
                [formGroup]="expiredTemplate"
                (ngSubmit)="setExpiredTemp(expiredTemplate.value)"
              >
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput2"
                    >Subject</label
                  >
                  <input
                    type="text"
                    class="form-control"
                    formControlName="subject2"
                    id="subject2"
                    placeholder="Enter Subject.. (eg) Call Billing - Payment Expired"
                    value="{{ subject2 }}"
                  />
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlInput1"
                    >Name</label
                  >
                  <input
                    type="text"
                    formControlName="name2"
                    class="form-control"
                    placeholder="Enter Name.. (eg) Dear Admin,"
                    id="title2"
                    value="{{ title2 }}"
                  />
                </div>

                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Body</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    formControlName="body2"
                    placeholder="Enter some message"
                    id="body2"
                    value="{{ body2 }}"
                  ></textarea>
                </div>
                <div class="form-group">
                  <label class="required" for="exampleFormControlTextarea1"
                    >Signature</label
                  >
                  <textarea
                    class="form-control"
                    id="exampleFormControlTextarea1"
                    rows="3"
                    placeholder="Enter Signature"
                    value="{{ signature2 }}"
                    formControlName="signature2"
                    id="sign2"
                  ></textarea>
                </div>
                <div>
                  <!-- <button class="btn btn-outline-primary mr-2" type="submit">
                  Reset
                </button> -->
                  <button class="btn btn-primary" type="submit">Save</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</div>

<!-- <div class="container-fluid">
  
</div> -->
